
export let language = 'en-BR';

export function setLanguage(newLanguage) {
  language = newLanguage;
  if (newLanguage === 'en-BR') {
    setText(textEnBR);
  } else if (newLanguage === 'en-US') {
    setText(textEnUS);
  } else {
    setText(textEnBR);
  }
}

export let Text = {
  ENROLLMENT: 'Enrolment',
  ENROLLMENTS: 'Enrolments',
  ENROLL: 'Enrol',
  ORGANIZATION: 'Organisation',
};

let textEnBR = {
  ENROLLMENT: 'Enrolment',
  ENROLLMENTS: 'Enrolments',
  ENROLL: 'Enrol',
  ORGANIZATION: 'Organisation',
};

let textEnUS = {
  ENROLLMENT: 'Enrollment',
  ENROLLMENTS: 'Enrollments',
  ENROLL: 'Enroll',
  ORGANIZATION: 'Organization',
};

export function setText(newText) {
  Text = newText;
}

