const { parse, format, addSeconds, addHours } = require('date-fns');
const { utcToZonedTime } = require('date-fns-tz');

function setCurrentDate(dateString) {
  const currentDate = parse(dateString, 'yyyy-MM-dd HH:mm:ss', new Date());
  localStorage.setItem('currentDate', currentDate.toISOString());
}

function getCurrentDate() {
  const storedDate = localStorage.getItem('currentDate');
  return storedDate ? new Date(storedDate) : new Date();
}

function updateCurrentDate() {
  let currentDate = getCurrentDate();
  currentDate = addSeconds(currentDate, 1);
  localStorage.setItem('currentDate', currentDate.toISOString());
}

setTimeout(() => {
  setInterval(updateCurrentDate, 1000);
}, 3000);

function combineDateAndTime(date) {
  const datePart = getResolvedDate(date?.date);
  let timePart = getResolvedDate(date?.time_start);
  if (timePart === 'Invalid Date') {
    timePart = new Date();
  }
  return new Date(
    datePart.getFullYear(),
    datePart.getMonth(),
    datePart.getDate(),
    timePart.getHours(),
    timePart.getMinutes(),
    timePart.getSeconds()
  );
}

function toUKFormat(dateString) {
  if (!dateString || dateString == "" || typeof dateString !== 'string') {
    return '';
  }
  const formats = [
    'yyyy-MM-dd',
    'MM/dd/yyyy',
    'dd/MM/yyyy',
    'yyyy/MM/dd',
    'dd-MM-yyyy',
    'MM-dd-yyyy',
    'yyyy.MM.dd',
    'MM.dd.yyyy',
    'dd.MM.yyyy',
  ];
  let parsedDate;
  for (const dateFormat of formats) {
    parsedDate = parse(dateString, dateFormat, new Date());
    if (!isNaN(parsedDate)) {
      break;
    }
  }
  return format(parsedDate, 'MMMM d, yyyy');
}

function convertToUKTime(time) {
  const timeZone = 'Europe/London';
  const zonedDate = utcToZonedTime(time, timeZone);
  return format(zonedDate, 'yyyy-MM-dd HH:mm:ssXXX', { timeZone });
}

function getResolvedDate(date) {
  if (date) {
    if (date._seconds) {
      return new Date(date._seconds * 1000);
    } else if (date.seconds) {
      return new Date(date.seconds * 1000);
    } else {
      try {
        return new Date(date.toDate());
      } catch (error) {
        try {
          return new Date(date);
        } catch (error) {
          return new Date();
        }
      }
    }
  } else {
    return 'Invalid Date';
  }
}

module.exports = { getCurrentDate, setCurrentDate, toUKFormat, convertToUKTime, combineDateAndTime, getResolvedDate };
