
const initialState = {
  isLoggedIn: false,
  isInitialized: false,
  user: null
};

const accountReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'REGISTER': {
      const { user } = action.payload;
      return {
        ...state,
        user
      };
    }
    case 'LOGIN': {
      const { user } = action.payload;
      return {
        ...state,
        isLoggedIn: true,
        isInitialized: true,
        user
      };
    }
    case 'LOGOUT': {
      return {
        ...state,
        isInitialized: true,
        isLoggedIn: false,
        user: null
      };
    }
    case 'UPDATE_USER_PROFILE': {
      return {
        ...state,
        user: action.payload.user
      };
    }
    default: {
      return { ...state };
    }
  }
};

export default accountReducer;
