// Third party Imports
import { createTheme } from "@mui/material/styles";

// Project Functions/Constants
const mainColor = "#6259F5";
const secondColor = "#F6F5FF";
const hoverColor = "#F2F4F7";
const secondaryColor = "#FFCD5A";
const baceColor = "#FFFFFF";
const tertiaryColor = "#B87CFD";
const bacelightColor = "#D4D4D4";
const bacemainColor = "#828282";
const bacedarkColor = "#47464F";
const darkColor = "#1A1A1A";
const infoColor = "#2685CA";
const errorColor = "#D92D20";
const successColor = "#38A169";
const secondSecondaryColor = "#FAF9FF";
const secondaryhover = "#F3EFF4";
const buttoncolor1 = "#2563EB";
const buttoncolor2 = "#3B82F6";
const baseFont = "epilogue";
const topicFont = "epilogue";
const baseCustom = "#E9E9E9";
const blackColor = "#2A2A2A";
const ligtmaincolor = "#4F44E2";
const lightsecondcolor = "#E6E0E9";
const textdark = "#1A1A1A";
const textdarker = "#011821";
const textgray="#5F5E62";
const mediumgray="#928F9A";
const darkgray="#47464F";


export const theme = createTheme({
  palette: {
    primary: {
      main: mainColor,
      lightmain: ligtmaincolor,
      mediumgraytext:mediumgray,
      darkgraytext:darkgray,
      divider:baseCustom,
      second: secondColor,
      lightsecond: lightsecondcolor,
      hover: hoverColor,
      black: blackColor,
      secondaryhover: secondaryhover,
    },
    secondary: {
      main: secondaryColor,
      secondary: secondSecondaryColor,
    },
    background: {
      default: baceColor,
    },
    tertiary: {
      main: tertiaryColor,
    },
    base: {
      base: baceColor,
      light: bacelightColor,
      main: bacemainColor,
      dark: bacedarkColor,
      text: textdark,
      textDark: textdarker,
      textLight:textgray
    },
    accent: {
      info: infoColor,
      error: errorColor,
      success: successColor,
      notification: secondaryColor,
    },
  },
  typography: {
    Header1: {
      fontFamily: topicFont,
      fontSize: { lg: "64px", md: "64px", sm: "64px", xs: "40px" },
      fontWeight: 700,
      lineHeight: { lg: "87px", xs: "40px", md: "87px", sm: "67px" },
      letterSpacing: "-0.02em",
      color: bacedarkColor,
    },
    purpletopic: {
      fontFamily: "inter",
      fontSize: { lg: "16px", md: "16px", sm: "16px", xs: "16px" },
      fontWeight: 500,
      lineHeight: { lg: "32px", xs: "32px", md: "32px", sm: "32px" },
      letterSpacing: "0em",
      color: '#4F2CB0',
    },
    blacktopic: {
      fontFamily: "inter",
      fontSize: { lg: "16px", md: "16px", sm: "16px", xs: "12px" },
      fontWeight: 400,
      lineHeight: { lg: "32px", xs: "16px", md: "32px", sm: "32px" },
      letterSpacing: "0em",
      color: '#000000',
    },
    Header0: {
      fontFamily: topicFont,
      fontSize: { lg: "124px",  xs: "40px" },
      fontWeight: 700,
      lineHeight: { lg: "87px", xs: "40px", md: "87px", sm: "67px" },
      letterSpacing: "-0.02em",
      color: bacedarkColor,
    },
    Header2: {
      fontFamily: topicFont,
      fontSize: { lg: "40px", md: "40px", sm: "40px", xs: "20px" },
      fontWeight: 700,
      lineHeight: { lg: "54px", md: "54px", sm: "54px", xs: "24px" },
      letterSpacing: "-0.02em",
      color: bacedarkColor,
    },
    Header3: {
      fontFamily: topicFont,
      fontSize: { lg: "24px", md: "24px", sm: "24px", xs: "20px" },
      fontWeight: 700,
      lineHeight: { lg: "33px", md: "33px", sm: "33px", xs: "23px" },
      letterSpacing: "-0.02em",
      color: bacedarkColor,
    },
    Subtitle: {
      fontFamily: baseFont,
      fontSize: { lg: "24px", md: "24px", sm: "24px", xs: "20px" },
      fontWeight: 500,
      lineHeight: { lg: "29px", md: "29px", sm: "29px", xs: "26px" },
      letterSpacing: "0px",
      color: bacedarkColor,
    },
    Subtitle1: {
      fontFamily: baseFont,
      fontSize: { lg: "24px", md: "24px", sm: "24px", xs: "16px" },
      fontWeight: 500,
      lineHeight: { lg: "29px", md: "29px", sm: "29px", xs: "26px" },
      letterSpacing: "0px",
      color: bacedarkColor,
    },
    Body: {
      fontFamily: baseFont,
      fontSize: "16px",
      fontWeight: 400,
      lineHeight: "19.36px",
      letterSpacing: "0px",
      color: bacedarkColor,
    },
    Bold: {
      fontFamily: baseFont,
      fontSize: "16px",
      fontWeight: 500,
      lineHeight: "19px",
      letterSpacing: "0px",
      color: bacedarkColor,
    },
    Small: {
      fontFamily: baseFont,
      fontSize: "14px",
      fontWeight: 500,
      lineHeight: "20px",
      letterSpacing: "0px",
      color: bacedarkColor,
    },
    Small600: {
      fontFamily: baseFont,
      fontSize: "14px",
      fontWeight: 600,
      lineHeight: "20px",
      letterSpacing: "0px",
      color: bacedarkColor,
    },
    CustomSmall: {
      fontFamily: baseFont,
      fontSize: "14px",
      fontWeight: 400,
      lineHeight: "20px",
      letterSpacing: "0px",
      color: bacedarkColor,
    },
    customSmall: {
      fontFamily: baseFont,
      fontSize: "14px",
      fontWeight: 500,
      lineHeight: "20px",
      letterSpacing: "0px",
      color: bacemainColor,
    },
    preTitle: {
      fontFamily: baseFont,
      fontSize: "12px",
      fontWeight: 700,
      lineHeight: "15px",
      letterSpacing: "0.03em",
      color: bacedarkColor,
    },
    preTitle1: {
      fontFamily: baseFont,
      fontSize: "12px",
      fontWeight: 700,
      lineHeight: "15px",
      letterSpacing: "0.03em",
      color: baceColor,
    },
    preTitle2: {
      fontFamily: baseFont,
      fontSize: "12px",
      fontWeight: 400,
      lineHeight: "15px",
      letterSpacing: "0.03em",
      color: bacedarkColor,
    },
    preTitle3: {
      fontFamily: baseFont,
      fontSize: "12px",
      fontWeight: 500,
      lineHeight: "15px",
      letterSpacing: "0.03em",
      color: bacedarkColor,
    },
    preTitle4: {
      fontFamily: baseFont,
      fontSize: "12px",
      fontWeight: 400,
      lineHeight: "15px",
      letterSpacing: "0.03em",
      color: bacelightColor,
    },
    buttonText: {
      fontFamily: baseFont,
      fontSize: "16px",
      fontWeight: 700,
      lineHeight: "19px",
      letterSpacing: "0.04em",
      color: bacedarkColor,
    },
    buttonText2: {
      fontFamily: baseFont,
      fontSize: { lg: "18px", md: "18px", sm: "18px", xs: "14px" },
      fontWeight: 700,
      lineHeight: "19px",
      letterSpacing: "0.04em",
      color: baceColor,
    },
    buttonText3: {
      fontFamily: baseFont,
      fontSize: { lg: "18px", md: "18px", sm: "18px", xs: "14px" },
      fontWeight: 700,
      lineHeight: "19px",
      letterSpacing: "0.04em",
      color: bacedarkColor,
    },
    buttonText5: {
      fontFamily: baseFont,
      fontSize: { lg: "16px", md: "18px", sm: "18px", xs: "14px" },
      fontWeight: 600,
      lineHeight: "19px",
      letterSpacing: "0.04em",
      color: bacedarkColor,
    },
    buttonText4: {
      fontFamily: baseFont,
      fontSize: { lg: "18px", md: "18px", sm: "18px", xs: "14px" },
      fontWeight: 700,
      lineHeight: "19px",
      letterSpacing: "0.04em",
      color: mainColor,
    },
    link: {
      fontFamily: baseFont,
      fontSize: "12px",
      fontWeight: 400,
      lineHeight: "16px",
      letterSpacing: "0px",
      cursor: "pointer",
      color: mainColor,
    },
    link2: {
      fontFamily: baseFont,
      fontSize: "16px",
      fontWeight: 500,
      lineHeight: "19px",
      letterSpacing: "0px",
      cursor: "pointer",
      color: mainColor,
    },
    dateTitle: {
      fontFamily: baseFont,
      fontSize: { lg: "18px", md: "18px", sm: "18px", xs: "14px" },
      fontWeight: 400,
      lineHeight: "24px",
      letterSpacing: "0.04em",
      color: bacedarkColor,
    },
    linkField: {
      fontFamily: baseFont,
      fontSize: "16px",
      fontWeight: 400,
      lineHeight: "24px",
      letterSpacing: "0px",
    },
    displayLarge: {
      fontFamily: topicFont,
      fontSize: "60px",
      fontWeight: 400,
      lineHeight: "64px",
      letterSpacing: "0px",
      color: bacedarkColor,
    },
    displayMedium: {
      fontFamily: topicFont,
      fontSize: "48px",
      fontWeight: 400,
      lineHeight: "52px",
      letterSpacing: "0em",
      color: bacedarkColor,
    },
    displaySmall: {
      fontFamily: topicFont,
      fontSize: "34px",
      fontWeight: 600,
      lineHeight: "44px",
      letterSpacing: "0em",
      color: bacedarkColor,
    },
    headlineLarge: {
      fontFamily: topicFont,
      fontSize: "32px",
      fontWeight: 400,
      lineHeight: "40px",
      letterSpacing: "0em",
      color: bacedarkColor,
    },
    headlineMedium: {
      fontFamily: topicFont,
      fontSize: "28px",
      fontWeight: 400,
      lineHeight: "36px",
      letterSpacing: "0em",
      color: bacedarkColor,
    },
    headlineSmall: {
      fontFamily: topicFont,
      fontSize: "24px",
      fontWeight: 600,
      lineHeight: "32px",
      letterSpacing: "0em",
      color: bacedarkColor,
    },
    titleLarge: {
      fontFamily: baseFont,
      fontSize: "22px",
      fontWeight: 400,
      lineHeight: "28px",
      letterSpacing: "0em",
      color: bacedarkColor,
    },
    titleMedium: {
      fontFamily: baseFont,
      fontSize: { lg: "16px", xs: "14px" },
      fontWeight: 500,
      lineHeight: "24px",
      letterSpacing: "0em",
      color: bacedarkColor,
    },
    titleMediumlink: {
      fontFamily: baseFont,
      fontSize: { lg: "16px", xs: "14px" },
      fontWeight: 500,
      lineHeight: "24px",
      letterSpacing: "0em",
      color: bacedarkColor,
      cursor:'pointer',
      "&:hover": {
        color: mainColor,
        textDecoration:'underline'
      },
    },
    titleMediumsame: {
      fontFamily: baseFont,
      fontSize:"16px" ,
      fontWeight: 500,
      lineHeight: "24px",
      letterSpacing: "0em",
      color: bacedarkColor,
    },
    titleSmall: {
      fontFamily: baseFont,
      fontSize: { lg: "14px", xs: "12px" },
      fontWeight: 500,
      lineHeight: "20px",
      letterSpacing: "0em",
      color: bacedarkColor,
    },
    bodyLarge: {
      fontFamily: baseFont,
      fontSize: "16px",
      fontWeight: 400,
      lineHeight: "24px",
      letterSpacing: "0em",
      color: bacedarkColor,
    },
    bodyLargesvg: {
      fontFamily: baseFont,
      fontSize: "14px",
      fontWeight: 700,
      lineHeight: "24px",
      letterSpacing: "0em",
      color: '#4F2CB0',
    },
    bodyLarge500: {
      fontFamily: baseFont,
      fontSize: "16px",
      fontWeight: 500,
      lineHeight: "24px",
      letterSpacing: "0em",
      color: bacedarkColor,
    },
    bodyLargeCustom: {
      fontFamily: baseFont,
      fontSize: "16px",
      fontWeight: 400,
      lineHeight: "24px",
      letterSpacing: "0em",
      color: mediumgray,
    },
    bodyMedium: {
      fontFamily: baseFont,
      fontSize: "14px",
      fontWeight: 400,
      lineHeight: "20px",
      letterSpacing: "0em",
      color: bacedarkColor,
    },
    bodyMediumwhite: {
      fontFamily: baseFont,
      fontSize: "14px",
      fontWeight: 400,
      lineHeight: "20px",
      letterSpacing: "0em",
      color: "#FFFFFF",
    },
    bodyMediumCustom: {
      fontFamily: baseFont,
      fontSize: "14px",
      fontWeight: 400,
      lineHeight: "20px",
      letterSpacing: "0em",
      color: mediumgray,
    },
    bodySmall: {
      fontFamily: baseFont,
      fontSize: "12px",
      fontWeight: 400,
      lineHeight: "16px",
      letterSpacing: "0em",
      color: bacedarkColor,
    },
    labelLarge: {
      fontFamily: baseFont,
      fontSize: "14px",
      fontWeight: 500,
      lineHeight: "20px",
      letterSpacing: "0em",
      color: bacedarkColor,
    },
    labelLargeCustom: {
      fontFamily: baseFont,
      fontSize: "14px",
      fontWeight: 500,
      lineHeight: "20px",
      letterSpacing: "0em",
      color: mediumgray,
    },
    labelMedium: {
      fontFamily: baseFont,
      fontSize: "12px",
      fontWeight: 500,
      lineHeight: "16px",
      letterSpacing: "0em",
      color: bacedarkColor,
    },
    labelSmall: {
      fontFamily: baseFont,
      fontSize: "11px",
      fontWeight: 500,
      lineHeight: "16px",
      letterSpacing: "0em",
      color: bacedarkColor,
    },
    labelmini: {
      fontFamily: baseFont,
      fontSize: {lg:"14px",xs:"7px"},
      fontWeight: 400,
      lineHeight: "20px",
      letterSpacing: "0em",
      color: bacedarkColor,
    },
  },
  components: {
    MuiButton: {
      variants: {
        large: {
          display: "inline-flex",
          padding: "18px 64px 18px 64px",
          alignItems: "flex-start",
          gap: "8px",
          borderRadius: "100px",
          width: "181px",
          height: "60px",
          top: "20px",
        },
        medium: {
          width: "149px",
          height: "48px",
          padding: "12px 48px 12px 48px",
          padding: "12px 48px",
          borderRadius: "100px",
          gap: "8px",
        },
        small: {
          width: "117px",
          height: "40px",
          padding: { lg: "8px 32px 8px 32px", xs: "8px 8px 8px 8px" },
          borderRadius: "100px",
          gap: "8px",
        },
        lowradius: {
          width: "117px",
          height: "40px",
          padding: { lg: "8px 32px 8px 32px", xs: "8px 8px 8px 8px" },
          borderRadius: "0px",
          gap: "8px",
        },
        NoPaddingsmall: {
          width: "117px",
          height: "40px",
          padding: { lg: "px 32px 8px 0px", xs: "8px 8px 8px 0px" },
          borderRadius: "100px",
          gap: "8px",
        },
        smaller: {
          width: "185px",
          height: "30px",
          padding:'10px 24px 8px 24px',
          borderRadius: "100px",
        },
        NoPadding: {
          width: "auto",
          height: "30px",
          padding:'0px 0px 0px 0px',
          borderRadius: "100px",
        },
      },
      states: {
        contained: {
          initial: {
            border: "1px solid transparent",
            borderRadius: "100px",
            background: '#FFBB00',
            color: '#272728',
            boxShadow: "transparent",
            "&:hover": {
              background: '#FFBB00',
              color: '#272728',
              boxShadow: "transparent",
            },
          },
          activity: {
            border: "1px solid transparent",
            borderRadius: "0px",
            background: '#FFBB00',
            color: '#272728',
            boxShadow: "transparent",
            "&:hover": {
              background: '#FFBB00',
              color: '#272728',
              boxShadow: "transparent",
            },
          },
          activity1: {
            border: "1px solid transparent",
            borderRadius: "4px",
            background: '#FFBB00',
            color: '#272728',
            boxShadow: "transparent",
            "&:hover": {
              background: '#FFBB00',
              color: '#272728',
              boxShadow: "transparent",
            },
          },
          delete: {
            border: "1px solid transparent",
            borderRadius: "100px",
            background: '#F55151',
            color: baceColor,
            boxShadow: "transparent",
            "&:hover": {
              background: '#F55151',
              color: baceColor,
              boxShadow: "transparent",
            },
          },
          header: {
            color: mainColor,
            background: secondaryhover,
            "&:hover": {
              background: secondSecondaryColor,
            },
          },
          hover: {
            border: "1px solid transparent",
            borderRadius: "100px",
            background: "var(--blue-600, #2563EB)",
            color: baceColor,
            boxShadow:
              "0px 12px 24px 0px rgba(29, 78, 216, 0.32), 0px 2px 4px 0px rgba(59, 130, 246, 0.12)",
            "&:hover": {
              background: "var(--blue-600, #2563EB)",
              boxShadow:
                "0px 12px 24px 0px rgba(29, 78, 216, 0.32), 0px 2px 4px 0px rgba(59, 130, 246, 0.12)",
            },
          },
          active: {
            border: "1px solid transparent",
            borderRadius: "100px",
            color: baceColor,
            background: "var(--blue-600, #2563EB)",
            boxShadow: "-5px -5px 10px 0px #FFF, 5px 5px 10px 0px #BEBEBE",
            "&:hover": {
              background: "var(--blue-600, #2563EB)",
              boxShadow: "-5px -5px 10px 0px #FFF, 5px 5px 10px 0px #BEBEBE",
            },
          },
          focus: {
            border: "4px solid #BFDBFE",
            color: baceColor,
            borderRadius: "100px",
            background: "var(--blue-500, #3B82F6)",
            boxShadow:
              "0px 2px 4px 0px rgba(52, 60, 68, 0.16), 0px 0px 1px 0px rgba(117, 131, 142, 0.04)",
            "&:hover": {
              background: "var(--blue-500, #3B82F6)",
              boxShadow:
                "0px 2px 4px 0px rgba(52, 60, 68, 0.16), 0px 0px 1px 0px rgba(117, 131, 142, 0.04)",
            },
          },
          disabled: {
            border: "1px solid transparent",
            borderRadius: "100px",
            opacity: 0.5,
            color: baceColor,
            background: "var(--blue-500, #3B82F6)",
            boxShadow:
              "0px 2px 4px 0px rgba(52, 60, 68, 0.16), 0px 0px 1px 0px rgba(117, 131, 142, 0.04)",
            "&:hover": {
              background: "var(--blue-500, #3B82F6)",
              boxShadow:
                "0px 2px 4px 0px rgba(52, 60, 68, 0.16), 0px 0px 1px 0px rgba(117, 131, 142, 0.04)",
            },
          },
        },
        outlined: {
          initial: {
            border: " 1px solid var(--blue-500, #6259F5)",
            color: mainColor,
            boxShadow: "transparent",
          },
          hover: {
            border: "1px solid var(--blue-600, #2563EB)",
            color: buttoncolor1,
            boxShadow: "transparent",
          },
          active: {
            border: "1px solid var(--blue-600, #2563EB)",
            color: buttoncolor1,
            boxShadow: "-5px -5px 10px 0px #FFF, 5px 5px 10px 0px #BEBEBE",
          },
          focus: {
            border: "1px solid var(--blue-200, #BFDBFE)",
            color: "#BFDBFE",
            boxShadow:
              "0px 2px 4px 0px rgba(52, 60, 68, 0.16), 0px 0px 1px 0px rgba(117, 131, 142, 0.04)",
          },
          disabled: {
            border: "1px solid var(--blue-500, #3B82F6)",
            color: buttoncolor2,
            opacity: 0.5,
            boxShadow:
              "0px 2px 4px 0px rgba(52, 60, 68, 0.16), 0px 0px 1px 0px rgba(117, 131, 142, 0.04)",
          },
          custom: {
            border: " 1px solid var(--blue-500, #3B82F6)",
            borderColor: baseCustom,
            backgroundColor: baceColor,
            borderRadius: "30px",
            color: bacedarkColor,
            height: "44px",
            width: "55px",
            "&:hover": {
              borderColor: baseCustom,
              color: bacedarkColor,
              backgroundColor: "rgba(33, 33, 33, 0.1)",
            },
          },
        },
        text: {
          initial: {
            color: mainColor,
            backgroundColor: "transparent",
            "&:hover": {
              background: "transparent",
              textDecoration: "underline" 
            },
          },
          cancel: {
            color: mediumgray,
            backgroundColor: "transparent",
            "&:hover": {
              background: "transparent",
              textDecoration: "underline" 
            },
            
          },
          delete: {
            color: '#D92D20',
            backgroundColor: "transparent",
            "&:hover": {
              background: "transparent",
              textDecoration: "underline" 
            },
            
          },
          header: {
            color: bacedarkColor,
            "&:hover": {
              background: "transparent",
            },
          },
          hover: {
            color: buttoncolor1,
            boxShadow:
              "0px 12px 24px 0px rgba(29, 78, 216, 0.32), 0px 2px 4px 0px rgba(59, 130, 246, 0.12)",
            "&:hover": {
              background: "transparent",
            },
          },
          active: {
            color: buttoncolor1,
            "&:hover": {
              background: "transparent",
            },
          },
          focus: {
            color: buttoncolor1,
            "&:hover": {
              background: "transparent",
            },
          },
          disabled: {
            color: buttoncolor2,
            "&:hover": {
              background: "transparent",
            },
            opacity: 0.5,
          },
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          flexShrink: 0,
          width: "14px",
          height: "14px",
          border: "1px solid var(--gray-300, #D0D5DD)",
          background: baceColor,
          "&.Mui-checked": {
            borderRadius: "4px",
            color: mainColor,
            border: "1px solid var(--Primary_Purple, #6259F5)",
            "& .MuiSvgIcon-root": {
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            },
          },
          "& .MuiSvgIcon-root": {
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          },
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          width: "400px",
          height: "44px",
          borderRadius: "4px",
          "& input": {
            backgroundColor: baceColor,
            boxSizing: "unset !important",
            height: "13px",
            fontSize: "16px",
            fontFamily: baseFont,
            fontWeight: 400,
          },
          "& fieldset": {
            borderColor: baceColor,
            borderRadius: "4px",
          },
          "& .MuiInput-root:before": {
            borderBottom: "transparent !important",
          },
          "& .MuiOutlinedInput-root": {
            background: baceColor,
          },
          "& .MuiOutlinedInput-notchedOutline": {
            border: "1px solid var(--error-300, #D0D5DD) !important",
          },
          "&.Mui-error": {
            borderRadius: "4px",
            background: "var(--White, #FFF)",
            boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
          },
          "& .custom-textfield": {
            height: "44px",
            borderRadius: "4px",
            backgroundColor: baceColor,
            padding: "10px",
            boxSizing: "unset !important",
            fontSize: "16px",
            color: "#333",
          },
        },
      },
      errorstyleOverrides: {
        root: {
          width: "400px",
          height: "44px",
          borderRadius: "4px",
          "& input": {
            backgroundColor: baceColor,
            boxSizing: "unset !important",
            height: "13px",
            fontSize: "16px",
            fontFamily: baseFont,
            fontWeight: 400,
          },
          "& fieldset": {
            borderColor: baceColor,
            borderRadius: "4px",
          },
          "& .MuiOutlinedInput-root": {
            background: baceColor,
          },
          "& .MuiOutlinedInput-notchedOutline": {
            border: "1px solid var(--error-300, #D92D20) !important",
          },
          "&.Mui-error": {
            borderRadius: "4px",
            background: "var(--White, #FFF)",
            boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
          },
        },
      },
      multilinestyleOverrides: {
        root: {
          width: "320px",
          minHeight: "130px !important",
          background: "#FFFFFF !important",
          "& input": {
            boxSizing: "unset !important",
            height: "130px !important",
            fontSize: "16px",
            fontFamily: baseFont,
            fontWeight: 400,
          },
          "& fieldset": {
            borderRadius: "4px",
            minHeight: "130px !important",
            fontSize: "16px",
            fontFamily: baseFont,
            fontWeight: 400,
          },
          "& .MuiOutlinedInput-notchedOutline": {
            border: "1px solid var(--error-300, #D0D5DD) !important",
          },
          "& .textarea#:r5i:": {
            background: "#FFFFFF !important",
            height: '125px !important',
          },
          "& .textarea#\:r3\:" :{
            height: '125px !important',
        },
          "& .MuiOutlinedInput-root": {
            background: "#FFFFFF !important",
          },
          "& .MuiOutlinedInput-input": {
            background: "#FFFFFF !important",
            fontSize: "16px",
            fontFamily: baseFont,
            fontWeight: 400,
            minHeight: "90px !important",
          },
        },
      },
      multilineerrstyleOverrides: {
        root: {
          width: "320px",
          minHeight: "128px",
          background: "#FFFFFF !important",
          "& input": {
            boxSizing: "unset !important",
            height: "128px",
          },
          "& fieldset": {
            borderRadius: "4px",
            height: "auto",
            minHeight: "128px",
          },
          "& .MuiOutlinedInput-notchedOutline": {
            border: "1px solid var(--error-300, #D0D5DD) !important",
          },
          "& .textarea#:r5i:": {
            background: "#FFFFFF !important",
          },
          "& .MuiOutlinedInput-root": {
            background: "#FFFFFF !important",
          },
          "& .MuiOutlinedInput-input": {
            background: "#FFFFFF !important",
          },
        },
      },
    },
    MuiLinearProgress: {
      styleOverrides: {
        root: {
          backgroundColor: "white !important",
          height: "8px",
          borderRadius: "4px",
          "& .css-1d04mns-MuiLinearProgress-bar1": {
            backgroundColor: "#6259F5 !important",
            borderRadius: "8px",
          },
          "& .css-1s87xgs": {
            backgroundColor: "#6259F5 !important",
            borderRadius: "4px",
          },
        },
        barDeterminate: {
          height: "8px",
          borderRadius: "4px",
          backgroundColor: "#EAECF0 !important",
        },
      },
    },
  },
  spacing: (factor) => `${0.25 * factor}rem`,
  space_1: "0.25rem",
  space_2: "0.5rem",
  space_3: "0.75rem",
  space_4: "1rem",
  space_5: "1.25rem",
  space_6: "1.5rem",
  space_8: "2rem",
  space_10: "2.5rem",
  space_12: "3rem",
  space_16: "4rem",
  space_20: "5rem",
  space_24: "6rem",
  space_32: "8rem",
  space_40: "10rem",
  space_48: "12rem",
  space_56: "14rem",
  space_64: "16rem",

  Containers: {
    values: {
      sm: 40 * 16,
      md: 48 * 16,
      lg: 64 * 16,
      xl: 80 * 16,
    },
  },
  breakpoints: {
    values: {
      desktop: 1280,
      iphone: 375,
    },
  },
  spacing: (factor) => `${0.25 * factor}rem`,
  grid: {
    desktop: {
      marginLeft: (theme) => theme.spacing(4),
      marginRight: (theme) => theme.spacing(4),
      marginWidth: (theme) =>
        theme.breakpoints.values.desktop - theme.spacing(4 * 2),
    },
    iphone: {
      marginLeft: (theme) => theme.spacing(1),
      marginRight: (theme) => theme.spacing(1),
      marginWidth: (theme) =>
        theme.breakpoints.values.iphone - theme.spacing(1 * 2),
    },
  },
});
