// React Imports
import { lazy } from "react";

// Third Party Imports
import GuestGuard from "utils/route-guard/GuestGuard";
import MinimalLayout from "layout/MinimalLayout";
import Loadable from "ui-component/Loadable";

const Signup = Loadable(lazy(() => import("views/pages/authentication/authentication2/Signup")));
const Pricing = Loadable(lazy(() => import("views/app_pages/Dashboard/Pricing")));
const AutoLogin = Loadable(lazy(() => import("views/pages/authentication/authentication2/AutoLogin")));
const Privacy = Loadable(lazy(() => import("views/app_pages/Dashboard/PrivacyPolicy")));
const Terms = Loadable(lazy(() => import("views/app_pages/Dashboard/TermsOfUse")));
const SetPassword = Loadable(lazy(() => import("views/pages/authentication/authentication2/SetPassword")));
const ResePassword2 = Loadable(lazy(() => import("views/pages/authentication/authentication2/ForgotPassword2")));
const ChangePasswordform = Loadable(lazy(() => import("views/pages/authentication/authentication2/ChangePasswordform")));
const Parentsetup = Loadable(lazy(() => import("views/pages/authentication/authentication2/Parentsetup")));
const Landingtest = Loadable(lazy(() => import("views/app_pages/Dashboard/Landingtest")));
const Landingtest2 = Loadable(lazy(() => import("views/app_pages/Dashboard/LandingTest2")));
const Features = Loadable(lazy(() => import("views/app_pages/Dashboard/Features")));
const MovingToOutclass = Loadable(lazy(() => import("views/app_pages/Dashboard/MovingToOutclass")));
const StoryMission = Loadable(lazy(() => import("views/app_pages/Dashboard/StoryMisson")));
const Pricingnew = Loadable(lazy(() => import("views/app_pages/Dashboard/Pricingnew")));
const PremierLand = Loadable(lazy(() => import("views/app_pages/Dashboard/PremierLand")));
const PremierActiviteies = Loadable(lazy(() => import("views/app_pages/Dashboard/PremierActiviteies")));
const PremierKnowledge = Loadable(lazy(() => import("views/app_pages/Dashboard/PremierKnowledgeHub")));
const Holidaycamps = Loadable(lazy(() => import("views/app_pages/Dashboard/Holidaycamps")));
const Clubland2 = Loadable(lazy(() => import("views/app_pages/Dashboard/Schoollanding")));
const Clubdata = Loadable(lazy(() => import("views/app_pages/Dashboard/Clubdata")));


const LoginRoutes = {
  path: "/",
  element: (
    <GuestGuard>
      <MinimalLayout />
    </GuestGuard>
  ),
  children: [
    {
      path: "/pricing",
      element: <Pricing />,
    },
    {
      path: "/land",
      element: <Landingtest />,
    },
    {
      path: "/movingtooutclass",
      element: <MovingToOutclass />,
    },
    {
      path: "/about-us1",
      element: <StoryMission />,
    },
    {
      path: "/land2",
      element: <Landingtest2 />,
    },
    {
      path: "/login",
      element: <Signup />,
    },
    {
      path: "/features",
      element: <Features />,
    },
    {
      path: "/set-password",
      element: <SetPassword />,
    },
    {
      path: "/reset-password",
      element: <ResePassword2 />,
    }, 
    {
      path: "/price",
      element: <Pricingnew />,
    },
    
    {
      path: "/new-password",
      element: <ChangePasswordform />,
    },
    {
      path: "/autologin",
      element: <AutoLogin />,
    },
    {
      path: "/terms-of-service",
      element: <Terms />,
    },
    {
      path: "/privacy-policy",
      element: <Privacy />,
    },
    {
      path: "/setup",
      element: <Parentsetup />,
    },
  
    {
      path: '/pr',
      element: <PremierLand />
    },
    {
      path: '/activities',
      element: <PremierActiviteies />
    },
    {
      path: '/about-us',
      element: <PremierKnowledge />
    },
    {
      path: '/club',
      element: <Holidaycamps />
    },
    {
      path: '/clubdash',
      element: <Clubland2 />
    },
    {
      path: '/clubdata',
      element: <Clubdata />
    },
  ],
};

export default LoginRoutes;
