// React Imports
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';

// Project funtions constrains
import { BASE_PATH } from 'config';
import { store, persister } from 'store';

// Project Components
import App from 'App';
import * as serviceWorker from 'serviceWorker';
import reportWebVitals from 'reportWebVitals';

// assets
// import 'assets/scss/style.scss';

const container = document.getElementById('root');
const root = createRoot(container);
root.render(
  <Provider store={store}>
    <BrowserRouter basename={BASE_PATH}>
      <App />
    </BrowserRouter>
  </Provider>
);

serviceWorker.unregister();
reportWebVitals();
