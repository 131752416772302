// React Imports
import PropTypes from 'prop-types';
import { createContext, useEffect, useReducer } from 'react';

//Project Components
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import { executeFunction } from 'config/firebase-config';
import accountReducer from 'store/accountReducer';
import Loader from 'ui-component/Loader';
import { FIREBASE_API } from 'config';
import CustomSnackbar, { openSnackbar } from '../views/app_pages/commons/CustomSnackbar';
import { setCurrency } from 'utils/CurrencyUtils';
import { setLanguage } from 'utils/LanguageUtils';

if (!firebase.apps.length) {
  firebase.initializeApp(FIREBASE_API);
}

const db = firebase.firestore();

const initialState = {
  isLoggedIn: false,
  isMobileVerified: false,
  isInitialized: false,
  user: null
};

const FirebaseContext = createContext(null);

export const FirebaseProvider = ({ children }) => {

  const [state, dispatch] = useReducer(accountReducer, initialState);

  useEffect(
    () =>
      firebase.auth().onAuthStateChanged((user) => {
        if (user) {
          setUser(user);
        } else {
          dispatch({
            type: 'LOGOUT',
          });
        }
      }),
    [dispatch]
  );

  const setUser = async (user) => {
    const userProfile = await getUserProfile(user);
    if (userProfile) {
      let data = {
        type: 'LOGIN',
        payload: {
          isLoggedIn: true,
          user: {
            id: user.uid,
            email: user.email,
            name: user.displayName,
            emailVerified: user.emailVerified,
            ...userProfile,
          },
        },
      }
      if (userProfile.role == "admin" && !userProfile.role_id && !userProfile.organization_id) {
        executeFunction('setAdminRoleClaims', {});
      }
      dispatch(data);
      if (userProfile.currency) {
        setCurrency(userProfile.currency);
      }
      if (userProfile.language) {
        setLanguage(userProfile.language);
      }
    }
  };

  const getUserProfile = async (user) => {
    try {
      const userProfileDoc = await db.collection('users').doc(user.uid).get();
      if (userProfileDoc.exists) {
        const userProfile = userProfileDoc.data();
        return userProfile;
      } else {
        // console.log('User profile not found');
        // await saveUserProfile(user.uid, user.email, 'provider', '', {});
        // const userProfileDoc = await db.collection('users').doc(user.uid).get();
        // const userProfile = userProfileDoc.data();
        // return userProfile;
        return null;
      }
    } catch (error) {
      console.error('Error getting user profile:', error);
    }
  };

  const firebaseEmailPasswordSignIn = async (email, password, redirect) => {
    await firebase.auth().signInWithEmailAndPassword(email, password);
    if (redirect) {
      window.location.href = '/' + redirect;
    } else {
      window.location.href = '/';
    }
  };

  const firebaseGoogleSignIn = async (organization_url, type, moreInfo) => {
    const provider = new firebase.auth.GoogleAuthProvider();
    if (moreInfo.redirect) {
      provider.setCustomParameters({
        'redirect_uri': moreInfo.redirect
      });
    }
    try {
      const userCredential = await firebase.auth().signInWithPopup(provider);
      const user = userCredential.user;
      if (userCredential.additionalUserInfo.isNewUser) {
        const fullName = user.displayName.split(' ');
        const firstName = fullName[0];
        const lastName = fullName.length > 1 ? fullName[1] : '';
        try {
          await saveUserProfile(user.uid, user.email, type, organization_url, {
            ...moreInfo,
            first_name: firstName,
            last_name: lastName
          });
        } catch (error) {
          console.error('Error saving user profile to Firestore:', error);
          throw new Error('Failed to save user profile. Please try again later.');
        }
      }
      if (moreInfo.redirect) {
        window.location.href = moreInfo.redirect;
      }
      return user;
    } catch (error) {
      if (error.message.includes('popup-closed-by-user')) {
        throw new Error('Google sign-in popup closed by user.');
      } else {
        throw new Error('An unknown error occurred. Please try again later.');
      }
    }
  };

  const firebaseRegister = async (email, type, organization_url, moreInfo) => {
    try {
      const userCredential = await firebase.auth().createUserWithEmailAndPassword(email, getRandomPassword());
      const user = userCredential.user;
      try {
        await user.sendEmailVerification({
          url: FIREBASE_API.domain + '/' + (moreInfo.redirect ? moreInfo.redirect : 'login') + '?email=' + email,
          handleCodeInApp: true,
        });
        try {
          if (!organization_url) {
            organization_url = '';
          }
          await saveUserProfile(user.uid, email, type, organization_url, moreInfo);
        } catch (error) {
          console.error('Error saving user profile to Firestore:', error);
          throw new Error('Failed to save user profile. Please try again later.');
        }
      } catch (error) {
        console.error('Error sending email verification:', error);
        throw new Error('Failed to send email verification. Please try again later.');
      }
      return user;
    } catch (error) {
      if (error.code === 'auth/email-already-in-use') {
        executeFunction('sendSignInEmail', { email: email, redirect: moreInfo.redirect ? moreInfo.redirect : '' });
        localStorage.setItem('emailForSignIn', email);
      } else if (error.code === 'auth/invalid-email') {
        openSnackbar(error.message, 'error');
        throw new Error('The email address is not valid.');
      } else if (error.code === 'auth/operation-not-allowed') {
        openSnackbar(error.message, 'error');
        throw new Error('Email/password accounts are not enabled.');
      } else {
        openSnackbar(error.message, 'error');
        throw new Error('An unknown error occurred. Please try again later.');
      }
    }
  };

  const firebaseReplaceUser = async (oldUserId, email, password, type, organization_url, image, mobile, organization) => {
    try {
      const userCredential = await firebase.auth().createUserWithEmailAndPassword(email, password);
      const newUser = userCredential.user;
      try {
        await newUser.sendEmailVerification({
          url: FIREBASE_API.domain,
          handleCodeInApp: true,
        });
        try {
          if (!organization_url) {
            organization_url = '';
          }
          await saveUserProfile(newUser.uid, email, type, organization_url, { image, mobile, organization });
        } catch (error) {
          console.error('Error saving new user profile to Firestore:', error);
          throw new Error('Failed to save new user profile. Please try again later.');
        }
      } catch (error) {
        console.error('Error sending email verification for new user:', error);
        throw new Error('Failed to send email verification for new user. Please try again later.');
      }
      // const classesRef = firebase.firestore().collection('classes');
      // const classesSnapshot = await classesRef.where('created_by', '==', oldUserId).get();
      // const batch = firebase.firestore().batch();
      // classesSnapshot.forEach(doc => {
      //   const classRef = classesRef.doc(doc.id);
      //   batch.update(classRef, { 'created_by': newUser.uid });
      // });
      // await batch.commit();
      const userRef = firebase.firestore().collection('users').doc(oldUserId);
      await userRef.update({
        state: 'DELETED'
      });
      return newUser;
    } catch (error) {
      console.error('Error during user replacement:', error);
      throw new Error('An error occurred during user replacement. Please try again later.');
    }
  };

  const assignClass = async (uid, email, class_id) => {
    try {
      const classDoc = await db.collection('classes').doc(class_id).get();
      const classData = classDoc.data();
      const instructors = classData.instructors;
      let valid = false;
      instructors.map(async (instructor) => {
        if (instructor.email == email) {
          valid = true;
        }
      });
      if (valid) {
        try {
          await db.collection('classes').doc(class_id).update({
            instructor_ids: firebase.firestore.FieldValue.arrayUnion(uid)
          });
        } catch (error) {
          console.error('Error updating class:', error);
        }
      }
    } catch (error) {
      console.error('Error assigning class:', error);
    }
  };

  const saveUserProfile = async (uid, email, role, organization_url, moreInfo) => {
    try {
      await db.collection('users').doc(uid).set({
        email: email,
        created: firebase.firestore.FieldValue.serverTimestamp(),
        updated: firebase.firestore.FieldValue.serverTimestamp(),
        role: role,
        state: 'NEW',
        first_name: moreInfo?.first_name ? moreInfo?.first_name : '',
        last_name: moreInfo?.last_name ? moreInfo?.last_name : '',
        image: moreInfo?.image ? moreInfo?.image : '',
        mobile: moreInfo?.mobile ? moreInfo?.mobile : '',
        organization: moreInfo?.organization ? moreInfo?.organization : '',
      });
      if (role == 'instructor') {
        assignClass(uid, email, moreInfo?.classId);
      }
    } catch (error) {
      console.error('Error saving user profile:', error);
    }
  };

  const saveUserProfileWithRole = async (uid, first_name, last_name, email, mobile, role, organization_id) => {
    try {
      await db.collection('users').doc(uid).set({
        email: email,
        created: firebase.firestore.FieldValue.serverTimestamp(),
        updated: firebase.firestore.FieldValue.serverTimestamp(),
        role: role,
        state: 'NEW',
        first_name: first_name,
        last_name: last_name,
        mobile: mobile,
        organization_id: organization_id
      });
    } catch (error) {
      console.error('Error saving user profile:', error);
    }
  };

  // const firebaseRegister = async (email, password) => firebase.auth().createUserWithEmailAndPassword(email, password);

  const firebaseRegisterWithPassword = async (email, password, first_name, last_name, mobile, role, redirect, organization_id) => {
    try {
      const userCredential = await firebase.auth().createUserWithEmailAndPassword(email, password);
      const user = userCredential.user;
      try {
        let url = FIREBASE_API.domain
        if (redirect) {
          url = url + '?redirect=' + redirect;
        }
        const actionCodeSettings = {
          url: url,
          handleCodeInApp: true,
        };
        await user.sendEmailVerification(actionCodeSettings);
      } catch (error) {
        console.error('Error sending email verification:', error);
        throw new Error('Failed to send email verification. Please try again later.');
      }
      try {
        if (organization_id) {
          executeFunction('setAdminRoleClaims', {});
        }
        await user.updateProfile({
          displayName: `${first_name} ${last_name}`
        });
      } catch (error) {
        console.error('Error updating user profile:', error);
        throw new Error('Failed to update user profile. Please try again later.');
      }
      try {
        await saveUserProfileWithRole(user.uid, first_name, last_name, email, mobile, role, organization_id);
        if (role == 'provider') {
          executeFunction('sendWelcomeProviderEmail', { email: email });
        }
      } catch (error) {
        console.error('Error saving user profile to Firestore:', error);
        throw new Error('Failed to save user profile. Please try again later.');
      }
      return user;
    } catch (error) {
      console.error('Error during registration:', error);
      openSnackbar(error.message, 'error');
      if (error.code === 'auth/email-already-in-use') {
        throw new Error('The email address is already in use by another account.');
      } else if (error.code === 'auth/invalid-email') {
        throw new Error('The email address is not valid.');
      } else if (error.code === 'auth/operation-not-allowed') {
        throw new Error('Email/password accounts are not enabled.');
      } else if (error.code === 'auth/weak-password') {
        throw new Error('The password is not strong enough.');
      } else {
        throw new Error('An unknown error occurred. Please try again later.');
      }
    }
  };

  const autoLogin = async (email, redirect) => {
    const storedEmail = localStorage.getItem('emailForSignIn');
    if (firebase.auth().isSignInWithEmailLink(window.location.href)) {
      firebase.auth().signInWithEmailLink(email, window.location.href)
        .then(userCredential => {
          localStorage.removeItem('emailForSignIn');
          window.location.href = redirect ? redirect : '/';
        }).catch(error => {
          console.error(error);
        });
    }
  }

  function getRandomPassword() {
    const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let password = '';
    for (let i = 0; i < 12; i++) {
      password += chars.charAt(Math.floor(Math.random() * chars.length));
    }
    return password;
  }

  const sendVerificationEmail = async (redirect) => {
    const user = firebase.auth().currentUser;
    let url = FIREBASE_API.domain
    if (redirect) {
      url = url + '?redirect=' + redirect;
    }
    const actionCodeSettings = {
      url: url,
      handleCodeInApp: true,
    };
    try {
      await user.sendEmailVerification(actionCodeSettings);
    } catch (error) {
      console.error('Error sending email verification:', error);
      throw new Error('Failed to send email verification. Please try again later.');
    }
  }

  const logout = async () => {
    firebase.auth().signOut();
  };

  const resetPassword = async (email, type) => {
    const actionCodeSettings = {
      url: `${FIREBASE_API.domain}/email-verification?email=${encodeURIComponent(email)}&type=${type}`
    };
    await firebase.auth().sendPasswordResetEmail(email, actionCodeSettings);
  };

  const resetPasswordWithFirebase = async (oobCode, newPassword) => {
    try {
      await firebase.auth().confirmPasswordReset(oobCode, newPassword);
    } catch (error) {
      throw error;
    }
  };

  const updateProfile = () => { };
  if (state.isInitialized !== undefined && !state.isInitialized) {
    return <Loader />;
  }

  const updateEmail = async (newEmail) => {
    try {
      const user = firebase.auth().currentUser;
      await user.updateEmail(newEmail);
      dispatch({
        type: 'UPDATE_EMAIL',
        payload: { email: newEmail },
      });
      return true;
    } catch (error) {
      console.error(error);
      return false;
    }
  };

  const updatePassword = async (oldPassword, newPassword) => {
    try {
      const user = firebase.auth().currentUser;
      const credential = firebase.auth.EmailAuthProvider.credential(user.email, oldPassword);
      await user.reauthenticateWithCredential(credential);
      await user.updatePassword(newPassword);
      return true;
    } catch (error) {
      console.error(error);
      return false;
    }
  };

  const verifyMobileOTP = async (phone, otp) => {
    try {
      return await executeFunction('verifyMobileOTP', { phone, otp });
    } catch (error) {
      console.error('Error verifying mobile OTP:', error);
      throw new Error('Failed to verify mobile OTP. Please try again later.');
    }
  };

  const updateUserProfile = async (uid, updatedData) => {
    const user = firebase.auth().currentUser;
    try {
      await db.collection("users").doc(uid).update(updatedData);
      const mergedUserData = { ...state.user, ...updatedData };
      dispatch({
        type: 'UPDATE_USER_PROFILE',
        payload: { user: mergedUserData },
      });
    } catch (error) {
      console.error("Error updating user profile:", error);
    }
  };


  const exchangeCodeForToken = async (code) => {
    return await executeFunction('exchangeCodeForToken', { code });
  };

  return (
    <FirebaseContext.Provider
      value={{
        ...state,
        firebaseRegister,
        firebaseRegisterWithPassword,
        firebaseEmailPasswordSignIn,
        login: () => { },
        firebaseGoogleSignIn,
        firebaseReplaceUser,
        logout,
        sendVerificationEmail,
        resetPassword,
        resetPasswordWithFirebase,
        updateProfile,
        updateEmail,
        updatePassword,
        updateUserProfile,
        verifyMobileOTP,
        exchangeCodeForToken,
        autoLogin
      }}
    >
      {children}
    </FirebaseContext.Provider>
  );
};

FirebaseProvider.propTypes = {
  children: PropTypes.node
};

export default FirebaseContext;
