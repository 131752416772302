
export let currency = 'GBP';
export let currencySymbol = '£';

export function setCurrency(newCurrency) {
  currency = newCurrency;
  if (newCurrency === 'GBP') {
    currencySymbol = '£';
  } else if (newCurrency === 'USD') {
    currencySymbol = '$';
  } else if (newCurrency === 'EUR') {
    currencySymbol = '€';
  }
}

export function getCurrencySymbol(code) {
  if (code === 'GBP') {
    return '£';
  } else if (code === 'USD') {
    return '$';
  } else if (code === 'EUR') {
    return '€';
  } else {
    return '£';
  }
}
