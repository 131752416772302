import LAYOUT_CONST from 'constant';

// dev
export const FIREBASE_API = {
    apiKey: "AIzaSyCwdy2dRp4wjFsxIOPbq7aGyhbMD1z_ROw",
    authDomain: "outclass-dev-cf044.firebaseapp.com",
    projectId: "outclass-dev-cf044",
    storageBucket: "outclass-dev-cf044.appspot.com",
    messagingSenderId: "639847334470",
    appId: "1:639847334470:web:e30d16a945009a60d9c047",
    measurementId: "G-XVMCQGB25N",
    domain: 'https://app-dev.outclass.io'
};

export const STRIPE_API = { 
    key: 'pk_test_51MLzCnB3A7mfMXF1I0FyiqRPZB3L9QIKYlpwnLjCz54AfxHkvjHZ7EaTIYzAiBrfCRCmDEgBmoz7uMdfBCrYHyQO00VsHEmXSp',
    stripeClientId: 'ca_NxWZswK0IjTpAFubJydOmf5HcK17bG5W',
    oAuthCallbackUrl: 'https://europe-west2-outclass-dev-cf044.cloudfunctions.net/handleStripeOAuthCallback'
};

// prod
// export const FIREBASE_API = {
//     apiKey: "AIzaSyCR4AKXwYSqwyhe-TiRH6ePc0Doi-hEh6Q",
//     authDomain: "outclass-io.firebaseapp.com",
//     projectId: "outclass-io",
//     storageBucket: "outclass-io.appspot.com",
//     messagingSenderId: "1098167030611",
//     appId: "1:1098167030611:web:b031fa93694062c0ad87a6",
//     measurementId: "G-ZDRZZ0TLBZ",
//     domain: 'https://outclass.io'
// };

// export const STRIPE_API = {
//     key: 'pk_live_51MLzCnB3A7mfMXF1qLKQPCz7FHlvIiSmR3y0NRQV87lJtoIL74hmxIH6ys9aJJsHsNG3Rjo9eTWedNl7PRcLBN1P00ixTYMWea',
//     stripeClientId: 'ca_NxWZECkEF91RfXTsQKHMkcbZ3pyBpWtG',
//     oAuthCallbackUrl: 'https://europe-west2-outclass-io.cloudfunctions.net/handleStripeOAuthCallback'
// };

export const MAILCHIMP_API = {
    name: 'outclass',
    key: '-us10'
}

export const BASE_PATH = '';

export const DASHBOARD_PATH = '/';

export const HORIZONTAL_MAX_ITEM = 6;

const config = {
    layout: LAYOUT_CONST.VERTICAL_LAYOUT, // vertical, horizontal
    drawerType: LAYOUT_CONST.DEFAULT_DRAWER, // default, mini-drawer
    fontFamily: `'Roboto', sans-serif`,
    borderRadius: 8,
    outlinedFilled: true,
    navType: 'light', // light, dark
    presetColor: 'default', // default, theme1, theme2, theme3, theme4, theme5, theme6
    locale: 'en', // 'en' - English, 'fr' - French, 'ro' - Romanian, 'zh' - Chinese
    container: false
};

export default config;
