
import { FIREBASE_API } from 'config';
import { initializeApp } from 'firebase/app';
import { getFunctions, httpsCallable } from 'firebase/functions';

const app = initializeApp(FIREBASE_API);
const functions = getFunctions(app, 'europe-west2');
// const functions = getFunctions(app, 'us-central1');

export const executeFunc = async (functionName, data) => {
	const callable = httpsCallable(functions, functionName);
	return callable(data);
};

export const executeFunction = async (functionName, data) => {
	const callable = httpsCallable(functions, functionName);
	const response = await callable(data);
	return response.data;
};
