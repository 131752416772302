// React Imports
import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

// project imports
import useAuth from 'hooks/useAuth';
import { DASHBOARD_PATH } from 'config';

const GuestGuard = ({ children }) => {
  const { isLoggedIn ,user} = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const urlParams = new URLSearchParams(window.location.search);
  const action = urlParams.get("action");
  const redirect = urlParams.get("redirect");

  useEffect(() => {
    if (isLoggedIn) {
      if (location?.pathname == '/login' && action == "accept_invitation") {
        navigate('/' + redirect);
      } else {
        if (user?.emailVerified == false) {
          navigate(DASHBOARD_PATH, { replace: true });
        }
      }
    }
  }, [isLoggedIn, navigate]);

  return children;
};

GuestGuard.propTypes = {
  children: PropTypes.node
};

export default GuestGuard;
