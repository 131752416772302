// React Imports
import React, { useState } from 'react';

// Project Imports
import { theme } from "config/ThemeConfig";

// Third Party Imports
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';

// Project Functions/Constants
let openSnackbarFn;

const CustomSnackbar = () => {
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState('');
  const [severity, setSeverity] = useState('error');
  const [vertical, setVertical] = useState('top');
  const [horizontal, setHorizontal] = useState('center');
  const [autoHideDuration, setAutoHideDuration] = useState(5000);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  openSnackbarFn = (msg, sev = 'error', hideSeconds) => {
    setMessage(msg);
    setSeverity(sev);
    setOpen(true);
    setAutoHideDuration(hideSeconds ? hideSeconds * 1000 : 5000);
  };

  return (
    <Snackbar open={open} autoHideDuration={autoHideDuration} onClose={handleClose} anchorOrigin={{ vertical, horizontal }} >
      <Alert
        onClose={handleClose}
        severity={severity}
        variant="filled"
        icon={severity === 'error' ? null : true}
        sx={{ color: theme.palette.background.default, backgroundColor: severity === 'error' ? theme.palette.accent.error : theme.palette.accent.success }}
      >
        {message}
      </Alert>
    </Snackbar>
  );
};

export function openSnackbar(message, severity, hideSeconds) {
  openSnackbarFn(message, severity, hideSeconds);
}

export default CustomSnackbar;
