// React Imports
import { useEffect, useState } from 'react';

// routing
import Routes from 'routes';
import Loader from 'ui-component/Loader';
import ThemeCustomization from 'themes';

// auth provider
import { FirebaseProvider as AuthProvider } from "./contexts/FirebaseContext";
import CustomSnackbar from './views/app_pages/commons/CustomSnackbar';
import { setCurrentDate } from './utils/DateUtils';
import { executeFunction } from 'config/firebase-config';

const App = () => {
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(true);
  }, []);

  useEffect(async () => {
    console.log("getCurrentTime");
    const getCurrentTime = await executeFunction("getCurrentTime");
    console.log(getCurrentTime.currentTime);
    setCurrentDate(getCurrentTime.currentTime);
  }, []);

  if (!loading) return <Loader />;

  return (
    <ThemeCustomization>
      <AuthProvider>
        <Routes />
        <CustomSnackbar />
      </AuthProvider>
    </ThemeCustomization>
  );
};

export default App;
