// Third Party Imports
import { combineReducers } from 'redux';

// project imports
import menuReducer from './slices/menu';

const reducer = combineReducers({
    menu: menuReducer
});

export default reducer;
